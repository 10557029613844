// src/utils/auth.js
// ref: https://auth0.com/blog/securing-gatsby-with-auth0/   Sam Julien
//
import auth0 from "auth0-js"
import { navigate } from "gatsby"
//import PropTypes from "prop-types"
//import React from "react"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: (process.env.AUTH0_DOMAIN) ? process.env.AUTH0_DOMAIN : "qat.eu.auth0.com",
      //domain: "quoc-anh.auth0.com", ou qat.eu.auth0.com
      clientID: (process.env.AUTH0_CLIENTID) ? process.env.AUTH0_CLIENTID : "NzrbjEs1opgo489vfW3lGRkCAvpNOr_v",
      //clientID: "k7X0yudkbWxxA2tH1jxfs_910RgMZXDu", ou NzrbjEs1opgo489vfW3lGRkCAvpNOr_v, zn5cQ7B7DYI5sRch1e323P9T8gHd2I0G
      redirectUri: (process.env.AUTH0_CALLBACK) ? process.env.AUTH0_CALLBACK : "https://x-memorial.netlify.app/callback",
      //redirectUri: "https://x-memorial.netlify.app/callback", changed to //x-memorial on oct 1ts , 2020
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("isLoggedIn") === "true"
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    cb()
    return
  }
  //localStorage.setItem("isLoggedIn", false)
  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    localStorage.setItem("isLoggedIn", true)
    navigate("/account")
    cb()
  }
}

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession())
}

export const getProfile = () => {
  return user
}

export const logout = () => {
  
  localStorage.setItem("isLoggedIn", false)
  
  auth.logout()
  return localStorage.getItem("isLoggedIn") === "false"
}